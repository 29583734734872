// jshint strict:false, undef:false, unused:false

/*/
	Crema Captcha Init
	Stephen Ginn at Crema Design Studio
	Updated 2020-12-09
/*/

(function () {
	// Data Masking
	document.querySelectorAll("[type=tel]").forEach(input => {
		IMask(input, {mask: '(000) 000-0000'});
	});
	
	// Crema Captcha v4.2
	captcha('[data-captcha]', {
		action: "https://formcarry.com/s/W03LCqSL2"
	});

	contact({
		success() {
			this.alert.innerHTML = this.msg;
			this.alert.className = "alert alert-warning";
		},
		error() {
			this.alert.innerHTML = this.msg;
			this.alert.className = "alert alert-danger";
		}
	});
})();
